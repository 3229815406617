import { User } from '@/interfaces/vuex/user';
import { computed, ref, onMounted, Ref } from 'vue';
import { StatusId } from '@/lib/enum';
import { useTagComposable } from '@/composables/useTag';
import { useDepartmentComposable } from '@/composables/useDepartment';
import { SdsFiltersAndIncludes } from '@/interfaces/api/general/company';

const LOCAL_STRAGE_KEY = 'sdsesFilterParams';

export type SdsesFilterParams = {
  statusGroupId: string[];
  uploadUsers: string[];
  tagIds: number[];
  departmentUuids: string[];
  sdsName: string;
  productName: string;
  revisedAtStartDate: string;
  revisedAtEndDate: string;
  createdAtStartDate: string;
  createdAtEndDate: string;
  isLatestOnly: boolean;
};

export type SdsesFilterParamsKeys = keyof SdsesFilterParams;
export type SdsesFilterParamsValue =
  | string
  | number
  | string[]
  | number[]
  | undefined;

const defaultValue: SdsesFilterParams = {
  statusGroupId: [], // Keep the status filter
  uploadUsers: [], // Add a filter for the upload user
  tagIds: [], //Add a filter for the tags
  departmentUuids: [], // Add a filter for the department
  sdsName: '',
  productName: '',
  revisedAtStartDate: '',
  revisedAtEndDate: '',
  createdAtStartDate: '',
  createdAtEndDate: '',
  isLatestOnly: false,
};

const selectableStatusList = StatusId.getDropdownOptions();

export const convertFilterParamsToRequest = (
  filterParams: SdsesFilterParams
): SdsFiltersAndIncludes => ({
  sds_name: filterParams.sdsName,
  product_name: filterParams.productName,
  revised_at_start_date: filterParams.revisedAtStartDate,
  revised_at_end_date: filterParams.revisedAtEndDate,
  created_at_start_date: filterParams.createdAtStartDate,
  created_at_end_date: filterParams.createdAtEndDate,
  status_id_list: filterParams.statusGroupId.length
    ? selectableStatusList
        .filter((statusId) =>
          filterParams.statusGroupId.includes(statusId.groupId)
        )
        .map((statusId) => statusId.value)
    : StatusId.values(),
  user_id_list: filterParams.uploadUsers,
  department_id_list: filterParams.departmentUuids,
  tag_id_list: filterParams.tagIds,
  is_latest_only: filterParams.isLatestOnly || false,
  //以下固定値
  has_product: true,
  has_section1: false,
  has_section2: false,
  has_section3: true,
  has_section8: false,
  has_section8_acgih: false,
  has_section8_jsoh: false,
  has_section8_dnel_dmel: false,
  has_section9: false,
  has_section14: true,
  has_section15: false,

  //要確認
  has_assistant_log_cannot_analysis: false,
  has_assistant_log_reconfirmation: false,
});

export const useSdsFilter = (
  uploadUsers: Ref<User[]>,
  tags: ReturnType<typeof useTagComposable>['tags'],
  departments: ReturnType<typeof useDepartmentComposable>['companyDepartments']
) => {
  //params
  const currentFilterParams = ref<SdsesFilterParams>({ ...defaultValue });
  const formFilterParams = ref<SdsesFilterParams>({ ...defaultValue });

  const updateFormFilterParam = (
    key: SdsesFilterParamsKeys,
    value: SdsesFilterParamsValue
  ) => {
    formFilterParams.value = {
      ...formFilterParams.value,
      [key]: value,
    };
  };

  const applyFormFilterParams = () => {
    currentFilterParams.value = formFilterParams.value;

    try {
      localStorage.setItem(
        LOCAL_STRAGE_KEY,
        JSON.stringify(formFilterParams.value)
      );
    } catch (e) {
      // do not anything
    }
  };

  const userOptions = computed(() =>
    uploadUsers.value.map((user) => ({
      value: user.uuid,
      title: [user.last_name, user.middle_name, user.first_name]
        .filter((text) => Boolean(text))
        .join(' ')
        .toLowerCase(),
    }))
  );

  const departmentOptions = computed(() =>
    departments.value.map((department) => ({
      value: department.uuid,
      title: department.name,
    }))
  );

  const tagOptions = computed(() =>
    tags.value.map((tag) => ({
      value: tag.id,
      title: tag.name,
    }))
  );

  const statusOptions = computed(() => {
    return Array.from(
      new Set(
        selectableStatusList.map((status) => ({
          value: status.groupId,
          state: StatusId.getStatusFromStatusId(status.value),
        }))
      )
    );
  });

  const hasFilterParams = computed(() =>
    Boolean(
      currentFilterParams.value.statusGroupId.length > 0 ||
        currentFilterParams.value.uploadUsers.length > 0 ||
        currentFilterParams.value.tagIds.length > 0 ||
        currentFilterParams.value.departmentUuids.length > 0 ||
        currentFilterParams.value.sdsName ||
        currentFilterParams.value.productName ||
        currentFilterParams.value.revisedAtStartDate ||
        currentFilterParams.value.revisedAtEndDate ||
        currentFilterParams.value.createdAtStartDate ||
        currentFilterParams.value.createdAtEndDate ||
        currentFilterParams.value.isLatestOnly
    )
  );

  onMounted(() => {
    try {
      const savedFilterParams = localStorage.getItem(LOCAL_STRAGE_KEY);

      if (savedFilterParams) {
        const params = {
          //過去のデータがどうなってるかわからないので念の為defaultを入れる
          ...defaultValue,
          ...JSON.parse(savedFilterParams),
        };
        currentFilterParams.value = params;
        formFilterParams.value = params;
      }
    } catch (error) {
      //do not anything
    }
  });

  return {
    currentFilterParams,
    formFilterParams,
    updateFormFilterParam,
    applyFormFilterParams,
    userOptions,
    departmentOptions,
    tagOptions,
    statusOptions,
    hasFilterParams,
  };
};

export type UseSdsFilter = ReturnType<typeof useSdsFilter>;
