import { render, staticRenderFns } from "./SdsBottomSheet.vue?vue&type=template&id=215c93dd&scoped=true"
import script from "./SdsBottomSheet.vue?vue&type=script&setup=true&lang=ts"
export * from "./SdsBottomSheet.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "215c93dd",
  null
  
)

export default component.exports