export const useDownloadExportSdses = () => {
  const getParamsFromQuery = () => {
    const queryParams = new URLSearchParams(location.href.split('?')[1]);

    return {
      hasExport: Boolean(queryParams.get('export')),
      bucket: queryParams.get('bucket'),
      companyId: queryParams.get('companyId'),
      filename: queryParams.get('filename'),
      googleQuery: {
        'X-Goog-Algorithm': queryParams.get('X-Goog-Algorithm') ?? '',
        'X-Goog-Credential': queryParams.get('X-Goog-Credential') ?? '',
        'X-Goog-Date': queryParams.get('X-Goog-Date') ?? '',
        'X-Goog-Expires': queryParams.get('X-Goog-Expires') ?? '',
        'X-Goog-SignedHeaders': queryParams.get('X-Goog-SignedHeaders') ?? '',
        'X-Goog-Signature': queryParams.get('X-Goog-Signature') ?? '',
      },
    };
  };

  const validateParams = ({
    bucket,
    companyId,
    filename,
  }: ReturnType<typeof getParamsFromQuery>) => bucket && companyId && filename;

  const downloadExportData = async (
    params: ReturnType<typeof getParamsFromQuery>
  ) => {
    const { bucket, companyId, filename, googleQuery } = params;
    const link = document.createElement('a');
    const urlSearchParam = new URLSearchParams(googleQuery).toString();
    const url = `https://storage.googleapis.com/${bucket}/export/${companyId}/${filename}?${urlSearchParam}`;
    const response = await fetch(url);
    const blob = await response.blob();
    const newBlob = new Blob([blob]);
    const objUrl = window.URL.createObjectURL(newBlob);
    link.href = objUrl;

    const tmp = filename?.split('.');
    const downloadFilename = tmp ? `${tmp[1]}.${tmp[2]}` : 'SDS一覧.xlsx';
    link.download = downloadFilename;
    link.click();
  };

  const downloadExportSdses = async () => {
    const params = getParamsFromQuery();
    if (!params.hasExport) {
      return;
    }

    if (!validateParams(params)) {
      throw new Error('there are not exist needed paramater');
    }

    await downloadExportData(params);
  };

  return {
    downloadExportSdses,
  };
};
