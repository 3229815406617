/**
 * ユーザーがリストから何かしらを選択するときに利用するcomposableです。
 * compare: 選択状態をtoggleする際にすでに選択されているかのチェックを行う関数です。
 */

import { Ref, ref, watchEffect } from 'vue';
import _ from 'lodash';

type UseSelectedItemsComposable<T> = [
  Ref<T[]>,
  Ref<boolean>,
  Ref<boolean>,
  Ref<boolean>,
  (targetItem: T) => void,
  (checked: boolean) => void,
  (checked: boolean) => void,
  () => void
];

const checkSelectedAllItems = <T>(
  items: T[],
  selectedItems: T[],
  compare: (item1: T, item2: T) => boolean
) => {
  if (items.length <= 0) {
    return false;
  }

  return items.every((targetItem) =>
    selectedItems.some((item) => compare(item as T, targetItem))
  );
};

const checkSelectedIndeterminateItems = <T>(
  isSelectedAllItems: boolean,
  items: T[],
  selectedItems: T[],
  compare: (item1: T, item2: T) => boolean
) => {
  if (items.length <= 0 || isSelectedAllItems) {
    return false;
  }

  return items.some((targetItem) =>
    selectedItems.some((item) => compare(item as T, targetItem))
  );
};

export const useSelectedItemsComposable = <T>(
  items: T[],
  compare: (item1: T, item2: T) => boolean
): UseSelectedItemsComposable<T> => {
  const reactiveItems = ref(items) as Ref<T[]>;

  const selectedItems = ref<T[]>([]) as Ref<T[]>;
  const isSelectedAllItems = ref(false);
  const isSelectedIndeterminateItems = ref(false);
  const isSelectedWholeItems = ref(false);

  const toggleSelectedItem = (targetItem: T) => {
    const isSelected = selectedItems.value.some((item) =>
      compare(item as T, targetItem)
    );

    isSelectedWholeItems.value = false;
    if (isSelected) {
      selectedItems.value = selectedItems.value.filter(
        (item) => !compare(item as T, targetItem)
      );
    } else {
      selectedItems.value = [...selectedItems.value, targetItem];
    }
  };

  const toggleAllSelectedItems = (checked: boolean) => {
    if (checked) {
      selectedItems.value = Array.from(
        new Set(_.uniqBy([...selectedItems.value, ...items], 'id'))
      );
    } else {
      const excludedUuids = selectedItems.value.filter(
        (targetItem) => !items.some((item) => compare(item as T, targetItem))
      );

      selectedItems.value = excludedUuids;
    }
  };

  const toggleWholeSelectedItems = (checked: boolean) => {
    isSelectedWholeItems.value = checked;
  };

  const resetSelectedItem = () => {
    isSelectedWholeItems.value = false;
    selectedItems.value = [];
  };

  watchEffect(() => {
    isSelectedAllItems.value = checkSelectedAllItems(
      reactiveItems.value,
      selectedItems.value,
      compare
    );
    isSelectedIndeterminateItems.value = checkSelectedIndeterminateItems(
      isSelectedAllItems.value,
      reactiveItems.value,
      selectedItems.value,
      compare
    );
  });

  return [
    selectedItems,
    isSelectedAllItems,
    isSelectedIndeterminateItems,
    isSelectedWholeItems,
    toggleSelectedItem,
    toggleAllSelectedItems,
    toggleWholeSelectedItems,
    resetSelectedItem,
  ];
};
